import { createApp, h } from "vue";
import App from "./App.vue";
import createStore from "./store";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import "uikit/dist/css/uikit.css";
import "@vuikit/theme";
import { createVfm } from "vue-final-modal";
import "./assets/scss/all.scss";
import { createVuetify } from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";
import * as components from "vuetify/components";

import VueClipboard from "vue-clipboard2";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
console.log("axios.defaults.baseURL:", axios.defaults.baseURL);

console.log("axios:", axios.defaults.baseURL);

UIkit.use(Icons);

const vuetify = createVuetify({
  components,
});

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  if (to.meta.public) {
    next();
  } else {
    return Promise.all([
      store.dispatch("checkAuth"),
      store.dispatch("checkSession"),
    ])
      .then(() => {
        next(); // should be called like this, otherwise it will not work (?)
      })
      .catch((error) => {
        if (
          error.response.status === 401 &&
          error.response.config &&
          !error.response.config.__isRetryRequest
        ) {
          next("/login");
        } else {
          next();
        }
      });
  }
});

const app = createApp({
  render: () => h(App),
});

const vfm = createVfm();
app.use(vfm);
app.use(vuetify);
app.use(VueAxios, axios);
const store = createStore();
app.use(router);
app.use(store);
app.use(VueClipboard);
router.$store = store;

app.mount("#app");
