   <template>
	<vue-final-modal
		name="invoice-modal"
		class="flex justify-center items-center"
		content-class="relative p-4 rounded-lg bg-white dark:bg-gray-900 yb-modal"
	>
		<div class="invoice-modal-block">
			<h2>Bitte überprüfe deine Rechnungsdaten</h2>
			<div class="invoice-input-block">
				<input
					class="uk-input"
					v-model="userData.firstName"
					placeholder="Vorname"
					type="text"
				/>
				<input
					class="uk-input"
					type="text"
					v-model="userData.lastName"
					placeholder="Surname"
				/>
				<input
					class="uk-input"
					type="text"
					v-model="userData.street"
					placeholder="Straße"
				/>
				<input
					type="text"
					class="uk-input"
					v-model="userData.houseNumber"
					placeholder="Housenr."
				/>
				<input
					type="text"
					class="uk-input"
					v-model="userData.zipCode"
					placeholder="PLZ"
				/>
				<input
					type="text"
					class="uk-input"
					v-model="userData.city"
					placeholder="Ort"
				/>
				<button
					class="uk-button uk-button-primary"
					@click="checkAndEmit"
				>
					Rechnung
				</button>
			</div>
		</div>
	</vue-final-modal>
</template>
     
    
    <script setup>
import { VueFinalModal } from "vue-final-modal";

const props = defineProps({
	userData: Object,
});

const emit = defineEmits(["click"]);

const checkAndEmit = () => {

	if (!props.userData.firstName) return alert("Bitte Vorname eingeben");
	if (!props.userData.lastName) return alert("Bitte Nachname eingeben")
	if (!props.userData.street) return alert("Bitte Straße eingeben");
	if (!props.userData.houseNumber) return alert("Bitte Hausnummer eingeben");
	if (!props.userData.zipCode) return alert("Bitte Postleitzahl eingeben");
	if (!props.userData.city) return alert("Bitte Ort eingeben");
	emit("click");
};
</script>

  <style>
.vfm {
	display: flex;
	justify-content: center;
	align-items: center;
}

.yb-modal {
	max-width: 800px;
	height: 500px;
}
</style>

        