import axios from 'axios'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  actions: {
    purchase(_, payload) {
      return axios.post('/credits/purchase', payload)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
    },
    transfer(_, payload) {
      return axios.post('/credits/transfer', payload)
          .then((response) => {
            // Assuming you might want to do something in the state with this response
            // commit('someMutation', response.data);
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
    },
  },
  mutations: {
  }
}
