import axios from 'axios'

export default {
    state : {
        vouchers: [],
    },
    getters: {
        getVouchers(state){
            return state.vouchers;
        }
    },
    actions: {
        setVoucher({commit}, payload) {
            commit('setVoucher', payload)
        },
        addVoucher(context, payload) {
            return axios.post('/voucher', payload)
                .then((response)=>{
                    return response;
                })
                .catch((error)=>{
                    return Promise.reject(error);
                })
        },
        updateVoucher(context, payload) {
            const { id } = payload;
            delete  payload.id;
            return axios.put('/voucher/' + id, payload)
                .then((response)=>{
                    return response;
                })
                .catch((error)=>{
                    return Promise.reject(error);
                })
        },
        getVoucher(contenxt, id){
            return axios.get('/voucher-edit/' + id)
                .then((response)=>{
                    return response;
                })
                .catch((error)=>{
                    return Promise.reject(error);
                })
        },
        getAllVouchers(context, queryObject = {}) {
            const query = new URLSearchParams(queryObject).toString();
            return axios.get(`/voucher${query ? '?' + query : '' }`)
                .then((response)=>{
                    context.commit('setVoucher', response.data.data);
                    return response;
                })
                .catch((error)=>{
                    return Promise.reject(error);
                })
        },
        deleteVoucher(context, id) {
            return axios.delete('/voucher/' + id)
                .then((response)=>{
                    return response;
                })
                .catch((error)=>{
                    return Promise.reject(error);
                })
        }
    },
    mutations: {
        setVoucher(state, payload) {
            state.vouchers = payload
        }
    }
}
