import { createRouter, createWebHashHistory } from 'vue-router';

const isLicense = () => router.$store.getters.getLoggedInUser && router.$store.getters.getLoggedInUser.isLicense;

const isAdmin = () => router.$store.getters.getLoggedInUser && router.$store.getters.getLoggedInUser.isAdmin;

const isSuperAdmin = () => router.$store.getters.getLoggedInUser && router.$store.getters.getLoggedInUser.isSuperAdmin;

const isAuthenticatedPlus = (to, from, next) => {
	// console.log('%crouter.js line:19 isAuthenticated', 'color: #007acc;', router.$store.getters.isAuthenticated, isAdmin());
	// console.log('%crouter.js line:20 object', 'color: #007acc;', JSON.stringify(router.$store.getters.getLoggedInUser));
	if(!router.$store.getters.isAuthenticated){
		next('/login')
	}else if(router.$store.getters.isAuthenticated && (isAdmin() || isLicense())){
		next();
	}else{
		next('/login');
	}
};

const isAuthenticatedAdmin = (to, from, next) => {
	// console.log('%crouter.js line:19 isAuthenticated', 'color: #007acc;', router.$store.getters.isAuthenticated, isAdmin());
	// console.log('%crouter.js line:20 object', 'color: #007acc;', JSON.stringify(router.$store.getters.getLoggedInUser));
	if(!router.$store.getters.isAuthenticated){
		next('/login')
	}else if(router.$store.getters.isAuthenticated && isAdmin()){
		next();
	}else{
		next('/login');
	}
};

const isAuthenticatedSuperAdmin = (to, from, next) => {
	if(!router.$store.getters.isAuthenticated){
		next('/login')
	}else if(router.$store.getters.isAuthenticated && isAdmin() && isSuperAdmin()){
		next();
	}else{
		next('/login');
	}
};

const router = createRouter({
	history: createWebHashHistory(),
	linkActiveClass: 'uk-active',
	linkExactActiveClass: 'uk-active',
	routes: [
		{
			name: 'Index',
			path: '/',
			component: () => import('@/components/User'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedAdmin,
		},
		{
			name: 'AddUser',
			path: '/add-user',
			component: () => import('@/components/AddUser'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedAdmin,
		},
		{
			name: 'EditUser',
			path: '/edit-user/:id',
			component: () => import('@/components/EditUser'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedAdmin,
		},
		{
			name: 'PlusListUser',
			path: '/plus-list-user',
			component: () => import('@/components/Plus/User'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedPlus,
		},
		{
			name: 'PlusAddUser',
			path: '/plus-add-user',
			component: () => import('@/components/Plus/AddUser'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedPlus,
		},
		{
			name: 'PlusEditUser',
			path: '/plus-edit-user/:id',
			component: () => import('@/components/Plus/EditUser'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedPlus,
		},
		{
			name: 'PlusCreditTransferHistory',
			path: '/plus-transfer-history',
			component: () => import('@/components/Plus/CreditTransferHistory'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedAdmin,
		},
		{
			name: 'PlusCreditPurchaseHistory',
			path: '/plus-purchase-history',
			component: () => import('@/components/Plus/CreditPurchaseHistory'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedAdmin,
		},
		{
			name: 'Login',
			path: '/login',
			component: () => import('@/components/Login'),
			meta: {
				public: true,
			},
		},
		{
			name: 'Admin',
			path: '/manage-admin',
			component: () => import('@/components/Admin'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedSuperAdmin,
		},
		{
			name: 'AddAdmin',
			path: '/add-admin',
			component: () => import('@/components/AddAdmin'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedSuperAdmin,
		},
		{
			name: 'EditAdmin',
			path: '/edit-admin/:id',
			component: () => import('@/components/EditAdmin'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedSuperAdmin,
		},
		{
			name: 'UserStats',
			path: '/user-stats',
			component: () => import('@/components/UserStats'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedSuperAdmin
		},
		{
			name: 'UserStatsDetails',
			path: '/user-stats-details/:id',
			component: () => import('@/components/UserStatsDetails'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedSuperAdmin,
		},
		{
			name: 'AdminStats',
			path: '/admin-stats',
			component: () => import('@/components/AdminStats'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedSuperAdmin,
		},
		{
			name: 'AdminStatsDetails',
			path: '/admin-stats-details/:id',
			component: () => import('@/components/AdminStatsDetails'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedSuperAdmin,
		},
		{
			name: 'UserPurchaseHistory',
			path: '/user-purchase-history',
			component: () => import('@/components/UserPurchaseHistory'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedSuperAdmin,
		},
		{
			name: 'AddVoucherPage',
			path: '/add-voucher',
			component: () => import('@/components/AddVoucher'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedSuperAdmin
		},
		{
			name: 'VouchersPage',
			path: '/vouchers',
			component: () => import('@/components/Vouchers'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedSuperAdmin
		},
		{
			name: 'EditVoucher',
			path: '/edit-voucher/:id',
			component: () => import('@/components/EditVoucher'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedSuperAdmin
		},
		{
			name: 'TranslationsPage',
			path: '/translations',
			component: () => import('@/components/Translations'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedSuperAdmin
		},
		{
			name: 'CompanyContactsPage',
			path: '/company-contacts',
			component: () => import('@/components/CompanyContacts'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedSuperAdmin
		},
		{
			name: 'KnownFromPage',
			path: '/known-from',
			component: () => import('@/components/KnownFrom'),
			meta: {
				public: false,
			},
			beforeEnter: isAuthenticatedSuperAdmin
		},
		{
			name: '403',
			path: '/403',
			component: () => import('@/components/403'),
			meta: {
				public: true
			}
		}
	]
})

export default router;