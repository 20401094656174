import axios from 'axios'

export default {
  namespaced: true,
  state: {
    users: [],
    trainers: null,
  },
  getters: {
    getAllUsers(state) {
      return state.users;
    },
    getTrainers(state) {
      return state.trainers;
    },
  },
  actions: {
    addUser(context, payload) {
      return axios
        .post("/plus/user", payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    updateUser(context, payload) {
      const { id } = payload;
      delete payload.id;
      return axios
        .put("/plus/user/" + id, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    deleteUser(context, payload) {
      return axios
          .delete("/plus/user/" + payload.id, {
            data: { role: payload.accountType },
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
    },
    impersonateUser(context, payload) {
      return axios
        .post("/impersonate-user/" + payload.id, {
          data: { role: payload.accountType },
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async getTrainers(context, queryObject = {}) {
        const query = new URLSearchParams(queryObject).toString();
        try {
          const response = await axios.get(`/plus/trainer${query ? "?" + query : ""}`);
          context.commit('setTrainers', response.data);
          return response.data;
        } catch(error)  {
          return Promise.reject(error);
        }
    },
    async changeTrainer(context, queryObject = {}) {
        const id = queryObject['id'];
        const query = new URLSearchParams(queryObject).toString();
        try {
          const response = await axios.post(`/plus/user/${id}/trainer${query ? "?" + query : ""}`);
          return response.data;
        } catch(error)  {
          return Promise.reject(error);
        }
    },
    getUser(context, id) {
      return axios
          .get("/plus/user/" + id)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
    },
    getAllUsers(context, queryObject = {}) {
      const query = new URLSearchParams(queryObject).toString();
      return axios
          .get(`/plus/user${query ? "?" + query : ""}`)
          .then((response) => {
            const users = response.data.data;
            context.commit("setUsers", users);
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
    },
    exportAllUsers(context, queryObject = {}) {
      const query = new URLSearchParams(queryObject).toString();
      return axios
          .get(`/plus/user/export${query ? "?" + query : ""}`, { responseType: 'blob' })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
    },
    exportAllTransfers(context, queryObject = {}) {
      const query = new URLSearchParams(queryObject).toString();
      return axios
        .get(`/credits/transfer/export${query ? "?" + query : ""}`, { responseType: 'blob' })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    exportAllPurchases(context, queryObject = {}) {
      const query = new URLSearchParams(queryObject).toString();
      return axios
        .get(`/credits/purchase/export${query ? "?" + query : ""}`, { responseType: 'blob' })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    creditsPurchase(context, payload) {
      return axios
        .post("/plus/user", payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setUsers(state, payload) {
      state.users = payload;
    },
    setTrainers(state, payload) {
      state.trainers = payload;
    },
  }
}
