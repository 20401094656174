export default {
  computed: {
    loggedInUser() {
      return this.$store.getters.getLoggedInUser;
    },
    isLicense() {
      return this.loggedInUser && this.loggedInUser.isLicense;
    },
    isAdmin() {
      return this.loggedInUser && this.loggedInUser.isAdmin;
    },
    isAdminOnly() {
      return this.loggedInUser && this.loggedInUser.isAdmin && !this.loggedInUser.isSuperAdmin;
    },
    isPlus() {
      return this.loggedInUser && this.loggedInUser.admin_mode === 'credits' && ((this.loggedInUser.isAdmin && !this.loggedInUser.isSuperAdmin) || this.loggedInUser && this.loggedInUser.isLicense);
    },
    isSuperAdmin() {
      return this.loggedInUser && this.loggedInUser.isSuperAdmin;
    },
  },
};
