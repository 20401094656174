<template>

    <div class="uk-margin-top sidebar">
        <div v-if="isPlus && isLicense && !isSuperAdmin" class="title">Trainer</div>
          <router-link v-if="isPlus && !isSuperAdmin"
                   exact
                   to="/plus-list-user"
          >
            <li>
              <a>User verwalten</a>
            </li>
          </router-link>

          <router-link v-if="isPlus && !isSuperAdmin"
                       exact
                       to="/plus-add-user"
          >
            <li>
              <a>User hinzufügen</a>
            </li>
          </router-link>

      <div v-if="isAdmin" class="title">Admin</div>

        <router-link v-if="isAdmin"
                     exact
                     to="/"
        >
         
            <li><a v-if="isSuperAdmin">Trainer/User verwalten</a>
            <a v-else>Trainer verwalten</a></li>
        </router-link>

        <router-link v-if="isAdmin"
                     exact
                     to="/add-user"
        >
            <li><a v-if="isSuperAdmin">Trainer/User hinzufügen</a>
            <a v-else>Trainer hinzufügen</a>
            </li>
        </router-link>

      <router-link v-if="isPlus && isAdminOnly"
                   exact
                   to="/plus-transfer-history"
      >
        <li>
          <a>Transferhistorie</a>
        </li>
      </router-link>

      <router-link v-if="isPlus && isAdminOnly"
                   exact
                   to="/plus-purchase-history"
      >
        <li>
          <a>Credithistorie</a>
        </li>
      </router-link>

        <hr v-if="isSuperAdmin">

        <div v-if="isSuperAdmin" class="title">Superadmin</div>

        <router-link v-if="isSuperAdmin"
                     exact
                     to="/manage-admin"
        >
            <li><a>Admins verwalten</a></li>
        </router-link>

        <router-link v-if="isSuperAdmin"
                     exact
                     to="/add-admin"
        >
            <li><a>Admin hinzufügen</a></li>
        </router-link>
        <router-link
                v-if="isSuperAdmin"
                exact
                to="admin-stats"
        >
            <li><a>Statistiken Admins</a></li>
        </router-link>
        <router-link
                v-if="isSuperAdmin"
                exact
                to="user-stats"
        >
            <li><a>Statistiken Trainer/User</a></li>
        </router-link>
        <router-link
                v-if="isSuperAdmin"
                exact
                to="user-purchase-history"
        >
            <li><a>Kaufhistorie</a></li>
        </router-link>

      <router-link v-if="isSuperAdmin"
                   exact
                   to="/plus-purchase-history"
      >
        <li>
          <a>Credithistorie</a>
        </li>
      </router-link>

        <router-link
                v-if="isSuperAdmin"
                exact
                to="vouchers"
        >
            <li><a>Gutscheine verwalten</a></li>
        </router-link>
        <router-link
                v-if="isSuperAdmin"
                exact
                to="add-voucher"
        >
            <li><a>Gutschein hinzufügen</a></li>
        </router-link>
        <router-link
                v-if="isSuperAdmin"
                exact
                to="translations"
        >
            <li><a>Übersetzungen verwalten</a></li>
        </router-link>
        <router-link
          v-if="isSuperAdmin"
          exact
          to="company-contacts"
          tag="li"
        >
            <li><a>Firmenkontakte</a></li>
        </router-link>
        <router-link
            v-if="isSuperAdmin"
            exact
            to="known-from"
            tag="li"
        >
            <li><a>Bekannt aus</a></li>
        </router-link>
    </div>

</template>

<script>
    import authHelperMixin from '@/mixins/authHelperMixin';
    export default {
        name: "sidebar",
        mixins: [authHelperMixin],
    }
</script>

<style lang="scss">
    .sidebar {
        * { 
            margin-bottom: 10px;

            a {
                color: #000;
                font-weight: 400;
            }
        }
        .title {
            color: darkgray;
           font-weight: 400;
        }
    }
    .uk-nav-default  li a {
        font-weight: normal;
        font-size: 11pt;
        color:#000 !important;
    }
    .uk-nav-default  li a:hover {
        color:#00549e !important;
    }
    .uk-nav-default  li.uk-active  a {
        font-weight: bold;
        color:#000 !important;
    }
    li.uk-nav-header {
        font-weight: normal;
        color: #999 !important;
    }
</style>
