export const ID_TOKEN_KEY = 'id_token'
export const SESSION_TOKEN_KEY = 'session_token'
export const SESSION_TIME = 1800
export default {
    getToken () {
        return window.localStorage.getItem(ID_TOKEN_KEY);
    },
    saveToken (token) {
        window.localStorage.setItem(ID_TOKEN_KEY, token);
    },
    destroyToken () {
        window.localStorage.removeItem(ID_TOKEN_KEY);
    },
    getSession() {
        return window.localStorage.getItem(SESSION_TOKEN_KEY);
    },
    saveSession(sessionToken) {
        window.localStorage.setItem(SESSION_TOKEN_KEY, sessionToken);
    },
    destroySession(){
        window.localStorage.removeItem(SESSION_TOKEN_KEY);
    },
    isSessionOut(){
        const currentSession = localStorage.getItem(SESSION_TOKEN_KEY);
        /* session cleared/not created yet */
        if(!currentSession) return false;
        const dateNow = new Date();
        const sessionTime = (dateNow - new Date(currentSession))/1000;
        return sessionTime >= SESSION_TIME;
    }
}
